import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { isTextEmpty } from 'src/app/modules/core/utils/validators.util';
import { HttpClientService } from 'src/app/modules/core/services/http-client/http-client.service';
import { StoreService } from 'src/app/modules/core/services/store/store.service';
import { UserSessionRegisterModel } from 'src/app/modules/configurations/users/models/user-session-register.model';
import { SERVICE_PORTAL } from 'src/app/modules/core/data/apis.data';

import { UserAuthenticateModel } from '../../models/user-authenticate.model';
import { UserTokenModel } from '../../models/user-token.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClientService, private storeService: StoreService) {}

    public autenticacionTipoInterno(dataBody: UserAuthenticateModel): Observable<UserTokenModel> {
        return this.http.post<UserTokenModel>(
            '/v1/usuario/auteticacion/tipo/interno',
            SERVICE_PORTAL,
            dataBody
        );
    }

    public azureAdAutenticate(dataBody: UserSessionRegisterModel) {
        return this.http.post<UserTokenModel>(
            '/v1/usuario/auteticacion/tipo/azureAd',
            SERVICE_PORTAL,
            dataBody
        );
    }

    public recoverPassword(
        userAccess: string,
        dataBody: UserSessionRegisterModel
    ): Observable<void> {
        return this.http.put(
            `/v1/usuario/recuperarClave/usuarioAcceso/${userAccess}`,
            SERVICE_PORTAL,
            dataBody
        );
    }

    public isUserAutheticate(): boolean {
        const token = this.storeService.getAuthToken();
        if (isTextEmpty(token)) {
            return false;
        }
        return true;
    }
}
