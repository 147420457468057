export const environment = {
    linkConactPage: 'https://segurosequinoccial.com/contacto/',
    googleReCaptcha: '6Lf5pl0pAAAAAHgPzXNPZiTUu9Ov4m3AMjwgc4Eo',
    b2c: {
        authorizeUrl:
            'https://segurosequinoccialb2c.b2clogin.com/segurosequinoccialb2c.onmicrosoft.com/oauth2/v2.0/authorize',
        clientId: '3f947db6-de28-4984-96be-14dfb09f8c3d',
        reditectUriValidateToken:
            'https://portalservicios.segurosequinoccial.com/auth/token-validation',
        policySignUpSignIn: 'B2C_1_Equinoccial_Signin',
        policyResetPassword: 'B2C_1_Equinoccial_Reset',
        nonce: 'defaultNonce',
        scope: 'openid',
        responseType: 'id_token',
        responseMode: 'query',
        prompt: 'login',
        uiLocales: 'es',
    },
    azureAd: {
        authorizeUrl:
            'https://login.microsoftonline.com/8dc96250-aebf-493a-b3c1-5cf7269be26f/oauth2/v2.0/authorize',
        clientId: '0eb44710-46e9-4930-afed-b11737e5f376',
        reditectUriValidateToken:
            'https://portalservicios.segurosequinoccial.com/auth/token-validation',
        nonce: 'defaultNonce',
        scope: 'openid email',
        responseType: 'id_token',
        responseMode: 'query',
        prompt: 'login',
        uiLocales: 'es',
    },
    apis: {
        servicePortal: 'https://api-portalservicios-production.azurewebsites.net/api',
    },
    urlFormularioVida:
        'https://bpm.equivida.com/sysequivida/es/3dfl/820474191615f602d3c4b33049036948/55822104361e98415262320052629005.php',
    urlStatusVehicleClaimBpm:
        'https://bpm.equinoccialonline.com/sysworkflow/es/3sesa/ph_soluciones/services/siniestrosVeh/buscador_caso.php?id={numberCase}',
    urlStatusGeneralClaimBpm:
        'https://bpm.equinoccialonline.com/sysworkflow/es/3sesa/ph_soluciones/services/siniestrosGen/buscador_caso.php?id={numberCase}',
    enabledServiceWorkerModule: true,
    urlLopdp: 'https://segurosequinoccial.com/politica-proteccion-datos-privacidad/',
};
