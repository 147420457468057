<div class="app-alert" *ngIf="visibleToggle  && isMessageValid">
  <div class="modal-background">
    <div class="modal">
      <ng-container *ngIf="showCloserIcon">
        <div class="modal-closer" (click)="onClose()">
          <i class="pi pi-times"  style="font-size: 10px"></i>
        </div>
      </ng-container>
      <div class="modal-bandera"></div>
      <div class="modal-content">
        <div class="row">
          <div class="col-12 content-tile" [innerHtml]="title">
          </div>
        </div>
        <div class="row">
          <div class="col-12" [innerHtml]="message">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary" (click)="onClose()">
              ACEPTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
