/* eslint-disable @typescript-eslint/no-explicit-any */

export function isEmailValid(email: string): boolean {
    const rex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!rex.test(email)) return false;
    if (email.includes(';')) return false;
    if (email.includes(',')) return false;
    if (email.includes('?')) return false;
    if (email.includes('&')) return false;
    if (email.includes('/')) return false;
    if (email.includes('¬')) return false;
    if (email.includes('°')) return false;
    if (email.includes('!')) return false;
    const emailsParts = email.split('@');
    if (emailsParts[0]?.length <= 3) return false;
    const emailEnd = emailsParts[0]?.charAt(emailsParts[0]?.length - 1) || '';
    if (emailEnd == ' ') return false;
    return true;
}

export function isEmaiSesalValid(email: string): boolean {
    if (!isEmailValid(email)) return false;
    const emailParts = email.split('@');
    if (emailParts.length != 2) return false;
    if (emailParts[1]?.toLowerCase() != 'segurosequinoccial.com') return false;
    return true;
}

export function isCellPhoneValid(telefonoCelular: string): boolean {
    let texto = telefonoCelular;
    if (!texto) return false;
    if (texto.length <= 0) return false;
    texto = texto.replace(' ', '');
    if (texto.length < 0 || texto.length < 10 || texto.length > 10) return false;
    if (!texto.startsWith('09')) return false;
    if (texto === '0900000000') return false;
    if (texto === '0900000001') return false;
    if (texto === '0900000003') return false;
    if (texto === '0900000004') return false;
    if (texto === '0900000005') return false;
    if (texto === '0900000006') return false;
    if (texto === '0900000007') return false;
    if (texto === '0900000008') return false;
    if (texto === '0900000009') return false;
    if (texto === '0900000000') return false;
    return true;
}

// Validate phone numbers and cellphones and emptyt data
export function isPhoneValid(telefonoCelular: string): boolean {
    const texto = telefonoCelular.trim();
    const validStartNumbers = ['02', '03', '04', '05', '06', '07', '09'];
    const blockedNumbers = [
        '0900000000',
        '0900000001',
        '0900000002',
        '0900000003',
        '0900000004',
        '0900000005',
        '0900000006',
        '0900000007',
        '0900000008',
        '0900000009',
    ];
    if (!texto) return true;
    if (!validStartNumbers.some(prefix => texto.startsWith(prefix))) return false;
    if (['02', '03', '04', '05', '06', '07'].some(prefix => texto.startsWith(prefix))) {
        if (texto.length < 9 || texto.length > 9) return false;
    }
    if (blockedNumbers.includes(texto)) return false;
    if (texto.startsWith('09')) {
        if (texto.length < 10 || texto.length > 10) return false;
    }
    return true;
}

// validate emails and empty emails
export function isMaillValid(email: string = ''): boolean {
    if (!email || email.trim() === '') {
        return true;
    }
    const rex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!rex.test(email)) return false;
    if (email.includes(';')) return false;
    if (email.includes(',')) return false;
    if (email.includes('?')) return false;
    if (email.includes('&')) return false;
    if (email.includes('/')) return false;
    if (email.includes('¬')) return false;
    if (email.includes('°')) return false;
    if (email.includes('!')) return false;
    const emailsParts = email.split('@');
    if (emailsParts[0]?.length <= 3) return false;
    const emailEnd = emailsParts[0]?.charAt(emailsParts[0]?.length - 1) || '';
    if (emailEnd == ' ') return false;
    return true;
}

export function isGuidValid(guid?: string): boolean {
    if (!guid) return false;
    if (guid === null) return false;
    if (guid === 'undefined') return false;
    if (guid.length <= 0) return false;
    const guidSinEspecios = guid.replace(' ', '');
    if (guidSinEspecios.length <= 0) return false;
    if (guidSinEspecios === '00000000-0000-0000-0000-000000000000') return false;
    const guidSinGuionesYCero = guid.replace('-', '').replace('0', '');
    if (guidSinGuionesYCero?.length <= 0) return false;
    return true;
}

export function isGuidEmpty(guid?: string): boolean {
    const guidComprovated = guid || '';
    if (isTextEmpty(guidComprovated)) {
        return true;
    }
    const guidSinEspecios = guidComprovated.replace(' ', '') || '';
    if (guidSinEspecios.length <= 0) return true;
    if (guidSinEspecios === '00000000-0000-0000-0000-000000000000') return true;
    return false;
}

export function isTextEmpty(texto?: string): boolean {
    if (texto === undefined) return true;
    if (texto === null || texto === '') return true;
    if (typeof texto !== 'string')  return true;
    const textoValidate: string = texto ?? '';
    if (textoValidate.length <= 0) return true;
    const textWithOutSpace = textoValidate.replace(' ', '') ?? '';
    if (textWithOutSpace?.length <= 0) return true;
    if (textWithOutSpace === '') return true;
    return false;
}

export function isNumberValid(number?: number): boolean {
    if (number === undefined) return true;
    if (number === null) return true;
    if (number <= 0) return true;
    return false;
}

export function isRucValid(ruc: string): boolean {
    if (typeof ruc === 'string' && ruc.length === 13 && /^\d+$/.test(ruc)) {
        const digitos = `0${ruc}`.split('').map(Number);
        const codigoProvincia = digitos[1] * 10 + digitos[2];

        if (codigoProvincia >= 1 && (codigoProvincia <= 24 || codigoProvincia === 30)) {
            if (digitos[11] + digitos[12] + digitos[13] === 1) {
                let sumatotal = 0;
                let VAL1;
                let VAL2;
                let VAL3;
                let VAL4;
                let VAL5;
                let VAL6;
                let VAL7;
                let VAL8;
                let VAL9;
                let DVER;
                let SUMA;
                let RESD;
                let NCV;

                switch (digitos[3]) {
                    // PERSONA JURIDICA
                    case 9:
                        VAL1 = digitos[1] * 4;
                        VAL2 = digitos[2] * 3;
                        VAL3 = digitos[3] * 2;
                        VAL4 = digitos[4] * 7;
                        VAL5 = digitos[5] * 6;
                        VAL6 = digitos[6] * 5;
                        VAL7 = digitos[7] * 4;
                        VAL8 = digitos[8] * 3;
                        VAL9 = digitos[9] * 2;
                        DVER = digitos[10] * 1;
                        SUMA = VAL1 + VAL2 + VAL3 + VAL4 + VAL5 + VAL6 + VAL7 + VAL8 + VAL9;
                        RESD = SUMA % 11;
                        if (RESD === 1) {
                            return false;
                        }
                        NCV = RESD;
                        if (RESD !== 0) {
                            NCV = 11 - RESD;
                        }

                        if (DVER === NCV) {
                            return true;
                        }

                        break;
                    // PERSONA PUBLICA
                    case 6:
                        VAL1 = digitos[1] * 3;
                        VAL2 = digitos[2] * 2;
                        VAL3 = digitos[3] * 7;
                        VAL4 = digitos[4] * 6;
                        VAL5 = digitos[5] * 5;
                        VAL6 = digitos[6] * 4;
                        VAL7 = digitos[7] * 3;
                        VAL8 = digitos[8] * 2;
                        DVER = digitos[9] * 1;
                        SUMA = VAL1 + VAL2 + VAL3 + VAL4 + VAL5 + VAL6 + VAL7 + VAL8;
                        RESD = SUMA % 11;
                        if (RESD === 1) {
                            return false;
                        }

                        NCV = RESD;
                        if (RESD !== 0) {
                            NCV = 11 - RESD;
                        }

                        if (DVER === NCV) {
                            return true;
                        }
                        break;
                    // PERSONA NATURAL
                    case 7:
                    case 8:
                    case 5:
                    case 4:
                    case 3:
                    case 2:
                    case 1:
                    case 0:
                        // eslint-disable-next-line no-plusplus
                        for (let i = 1; i < digitos.length - 4; i++) {
                            let valor = digitos[i];
                            if (i % 2 !== 0) {
                                valor = digitos[i] * 2;
                                if (valor > 9) {
                                    valor -= 9;
                                }
                            }
                            sumatotal += valor;
                        }

                        // eslint-disable-next-line no-case-declarations
                        let digitoVerificador = sumatotal % 10;

                        if (digitoVerificador !== 0) {
                            digitoVerificador = 10 - (sumatotal % 10);
                        }

                        if (digitos[10] === digitoVerificador) {
                            return true;
                        }

                        break;
                    default:
                        return false;
                }
            }
        }
    }
    return false;
}

export function isCedulaValid(cedula?: string): boolean {
    if (typeof cedula === 'string' && cedula.length === 10 && /^\d+$/.test(cedula)) {
        const digitos = `0${cedula}`.split('').map(Number);
        const codigoProvincia = digitos[1] * 10 + digitos[2];

        if (codigoProvincia >= 1 && (codigoProvincia <= 24 || codigoProvincia === 30)) {
            let sumatotal = 0;
            for (let i = 1; i < digitos.length - 1; i += 1) {
                let valor = digitos[i];
                if (i % 2 !== 0) {
                    valor = digitos[i] * 2;
                    if (valor > 9) {
                        valor -= 9;
                    }
                }
                sumatotal += valor;
            }

            let digitoVerificador = sumatotal % 10;

            if (digitoVerificador !== 0) {
                digitoVerificador = 10 - (sumatotal % 10);
            }

            if (digitos[10] === digitoVerificador) {
                return true;
            }
        }
    }
    return false;
}

export function isTextToLong(texto: string, characters: number) {
    if (texto?.length >= characters) return true;
    return false;
}

export function isPasswordValid(password: string): boolean {
    if (isTextEmpty(password)) {
        return false;
    }
    if (password.length < 7) {
        return false;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCases = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    if (!hasUpperCase || !hasLowerCases || !hasNumbers) {
        return false;
    }
    return true;
}

export function isDateValid(textDate: string): boolean {
    if (isTextEmpty(textDate)) return false;
    const isValid = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/.test(textDate);
    if (!isValid) return false;
    try {
        Date.parse(textDate);
    } catch (error) {
        return false;
    }
    return true;
}
