/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, finalize } from 'rxjs';

import { AlertService } from '../services/alert/alert.service';
import { SpinnerService } from '../services/spinner/spinner.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService, private spinnerService: SpinnerService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = request.url;
        if (!url.includes('/usuario/validarSesesion')) {
            this.spinnerService.show('Cargando');
        }
        return next.handle(request).pipe(
            catchError((httpError: HttpErrorResponse) => {
                if (!url.includes('/usuario/validarSesesion')) {
                    this.detectarEstadoError(httpError);
                }
                this.spinnerService.hide();
                throw httpError;
            }),
            finalize(() => {
                if (!url.includes('/usuario/validarSesesion')) {
                    this.spinnerService.hide();
                }
            })
        );
    }

    private detectarEstadoError(error: HttpErrorResponse): void {
        let mensajeError = error.error ? error.error.Message || error.error.message : undefined;
        let codigoError = error.status;
        switch (codigoError) {
            case 500: {
                mensajeError = 'Servicio no disponible';
                this.alertService.show(mensajeError);
                break;
            }
            case 404:
                mensajeError = mensajeError || 'No se encontró recurso para la solicitud';
                this.alertService.show(mensajeError);
                break;
            case 401:
                mensajeError = mensajeError || 'Usuario no autorizado';
                this.alertService.show(mensajeError);
                break;
            case 400:
                mensajeError = mensajeError || 'Existe algún dato incorrecto en la solicitud';
                this.alertService.show(mensajeError);
                break;
            case 403: {
                mensajeError =
                    mensajeError || 'El usuario no tiene suficiente permiso para está solicitud';
                this.alertService.show(mensajeError);
                break;
            }
            case 405:
                mensajeError = 'Método en la solicitud no permitida';
                this.alertService.show(mensajeError);
                break;
            case 408:
                mensajeError = 'Por favor intente mas tarde';
                this.alertService.show(mensajeError);
                break;
            case 409: {
                mensajeError =
                    mensajeError ||
                    'La solicitud no se pudo completar debido a un conflicto con el estado actual del recurso de destino';
                this.alertService.show(mensajeError);
                break;
            }
            case 410: {
                this.alertService.show(mensajeError);
                break;
            }
            case 429:
                mensajeError = 'El usuario ha realizado muchas solicitudes. Inténtalo más tarde';
                this.alertService.show(mensajeError);
                break;
            case 0:
                mensajeError =
                    'Le solicitamos amablemente que <strong>vuelva a intentarlo</strong>, ya que nuestro servicio web experimentó una breve interrupción. Agradecemos su comprensión.';
                this.alertService.show(mensajeError);
                break;
            default:
                codigoError = 500;
                mensajeError = 'El servicio no se encuentra disponible';
                this.alertService.show(mensajeError);
        }
    }
}
