import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { UserModel } from 'src/app/modules/configurations/users/models/user.model';
import { SidebarService } from 'src/app/modules/core/services/sidebar/sidebar.service';
import { StoreService } from 'src/app/modules/core/services/store/store.service';
import { MenuService } from 'src/app/modules/configurations/menus/services/menu.service';
import { MenuModel } from 'src/app/modules/configurations/menus/model/menu.model';

import { isGuidValid } from '../../utils/validators.util';
import { APP_ROUTE_AUTH_LOGIN, APP_ROUTE_USER_MANAGER_ACCOUNT } from '../../data/app-routes.data';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {
  public windowWidth: number;
  public menuList!: MenuItem[];
  public menuUser: MenuItem[] = [];
  public userName: string;
  public userIcono: string;
  public userNameOriginal: string;
  private authTokenSubscription: Subscription;
  private toggleShowSideBar: boolean;

  constructor(
    private sidebarService: SidebarService,
    private menuService: MenuService,
    private storeService: StoreService,
    private router: Router
  ) {
    this.windowWidth = window.innerWidth;
    this.menuList = [];
    this.menuUser = this.setToggleMenuOptions(false);
    this.userName = 'Bienvenido';
    this.userIcono = 'pi-angle-down';
    this.userNameOriginal = this.userName;
    this.authTokenSubscription = new Subscription();
    this.toggleShowSideBar = false;
    this.toggleUserName();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.windowWidth = window.innerWidth || 499;
    this.toggleUserName();
  }

  public ngOnInit(): void {
    const user = this.storeService.getAuthUser();
    if (user) {
      this.setUser(user);
    }
    const menuListOnStorage = this.storeService.getMenuList();
    if (menuListOnStorage.length > 0) {
      this.setList(menuListOnStorage);
    } else {
      this.onGetMenuList();
    }
  }

  public ngOnDestroy(): void {
    this.authTokenSubscription.unsubscribe();
  }

  public setUser(userModel: UserModel | null) {
    if (userModel) {
      this.userName = userModel.nombreCompleto;
      this.userNameOriginal  = this.userName;
    }
    this.toggleUserName();
  }

  public toggleUserName(): void {
    if (this.windowWidth < 799) {
      this.userName = '';
      this.userIcono = 'pi-user';
      this.menuUser = this.setToggleMenuOptions(true);
    } else {
      this.userName = this.userNameOriginal;
      this.userIcono = 'pi-angle-down';
      this.menuUser = this.setToggleMenuOptions(false);
    }
  }
  public setToggleMenuOptions(showNameUser: boolean): MenuItem[] {
    if (showNameUser) {
      return [
        {
          label: this.userNameOriginal,
          icon: 'pi pi-fw pi-user'
        },
        {
          label: 'Cuenta',
          icon: 'pi pi-fw pi-user-edit',
          command: () => {
            this.onOpenUserAcount();
          }
        }
      ];
    } else {
      return [
        {
          label: 'Cuenta',
          icon: 'pi pi-fw pi-user-edit',
          command: () => {
            this.onOpenUserAcount();
          }
        }
      ];
    }
  }

  public onSignOut(): void {
    this.router.navigate([APP_ROUTE_AUTH_LOGIN]);
  }

  public onOpenUserAcount(): void {
    this.router.navigate([APP_ROUTE_USER_MANAGER_ACCOUNT]);
  }

  public onToggleShowSideBard(): void {
    const toggleSideBar = !this.toggleShowSideBar;
    this.sidebarService.show(toggleSideBar);
  }

  public onGetMenuList() {
    const user = this.storeService.getAuthUser();
    const userId = user?.id || '';
    if (isGuidValid(userId)) {
      this.getListOnService(userId);
    }
  }

  public getListOnService(userId: string): void {
    this.menuList = [];
    this.menuService.getByUserId(userId).subscribe({
      next: (menuModelList) => {
        this.storeService.setMenuList(menuModelList);
        this.setList(menuModelList);
      }
    });
  }

  // public setList(menuModelList: MenuModel[]): void {
  //   this.menuList = [];
  //   menuModelList.sort((a, b) => a.sortOrder - b.sortOrder);
  //   const menuParentList = menuModelList.filter(menu => menu.parentMenuId == '' || menu.parentMenuId == null);
  //   menuParentList.forEach((menuParent: MenuModel) => {
  //     const menuChildList = menuModelList.filter(
  //       menu => menu.parentMenuId?.toLowerCase() == menuParent?.id?.toLowerCase()
  //     );
  //     const menuChildItemList = this.generateMenuChildItemList(menuChildList);
  //     const menuParentItem: MenuItem = {
  //       label: menuParent.name,
  //       icon: menuParent.icono,
  //       items: menuChildItemList
  //     };
  //     this.menuList.push(menuParentItem);
  //   });
  // }
  public setList(menuModelList: MenuModel[]): void {
    this.menuList = [];
    menuModelList.sort((a, b) => a.sortOrder - b.sortOrder);
    const menuParentList = menuModelList.filter(
      (menu) => menu.parentMenuId == '' || menu.parentMenuId == null
    );
    menuParentList.forEach((menuParent: MenuModel) => {
      const menuChildList = menuModelList.filter(
        (menu) =>
          menu.parentMenuId?.toLowerCase() == menuParent?.id?.toLowerCase()
      );
      const menuChildItemList = this.generateMenuChildItemList(menuChildList);

      // If the parent menu has no child items, set a default route for it
      let defaultRoute: string;
      if (menuChildItemList.length === 0) {
        defaultRoute = menuParent.route;
      } else {
        defaultRoute = '';
      }

      const menuParentItem: MenuItem = {
        label: menuParent.name,
        icon: menuParent.icono,
        items: menuChildItemList,
        routerLink: defaultRoute
      };
      this.menuList.push(menuParentItem);
    });
  }

  public generateMenuChildItemList(menuList: MenuModel[]): MenuItem[] {
    const menuChildItemList: MenuItem[] = [];
    menuList.forEach((menuChild) => {
      const menuItem: MenuItem = {
        label: menuChild.name,
        icon: menuChild.icono,
        routerLink: menuChild.route
      };
      menuChildItemList.push(menuItem);
    });
    return menuChildItemList;
  }

  public reditectToRouteSelected(routeSelected: string): void {
    if (routeSelected && routeSelected !== '') {
      this.router.navigate([routeSelected]);
    }
  }
}
