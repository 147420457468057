import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertConfirmModel } from 'src/app/modules/core/models/alert-confirm.model';
import { AlertConfirmService } from 'src/app/modules/core/services/alert-confirm/alert-confirm.service';

import { isTextEmpty } from '../../utils/validators.util';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.scss']
})
export class AlertConfirmComponent implements OnInit, OnDestroy {
  private alertConfirmSubscription: Subscription;
  public visibleToggle: boolean;
  public message:string;

  constructor(
    private alertConfirmService: AlertConfirmService
  ) {
    this.visibleToggle = false;
    this.message = '';
    this.alertConfirmSubscription = new Subscription();
  }

  public ngOnInit(): void {
    this.alertConfirmSubscription = this.alertConfirmService.getAlertConfirm().subscribe({
      next: (alertConfirmModel) => {
        if (this.isMessageValid(alertConfirmModel)) {
          this.visibleToggle = true;
          this.message = alertConfirmModel.message;
        } else {
          this.visibleToggle = false;
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.alertConfirmSubscription.unsubscribe();
  }

  public onAccepted(): void {
    this.visibleToggle = false;
    this.alertConfirmService.setResponse(true);
  }

  public onCancel(): void {
    this.visibleToggle = false;
    this.alertConfirmService.setResponse(false);
  }

  private isMessageValid(alertConfirmModel: AlertConfirmModel): boolean {
    if (isTextEmpty(alertConfirmModel.message)) {
      return false;
    }
    return true;
  }
}
