import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SpinnerModel } from 'src/app/modules/core/models/spinner.model';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private $spinnerVisible = new ReplaySubject<SpinnerModel>();
  constructor() { }

  public getSpinner(): Observable<SpinnerModel> {
    return this.$spinnerVisible.asObservable();
  }

  public show(customText: string): void {
    const spinnerModel: SpinnerModel = { 
      isVisible: true,
      customText
    }
    this.$spinnerVisible.next(spinnerModel);
  }

  public hide(): void {
    const spinnerModel: SpinnerModel = {
      isVisible: false,
      customText: ''
    }
    this.$spinnerVisible.next(spinnerModel);
  }
}
