<div class="app-layout-authenticate">
    <div class="grid">
        <!--INICIO MENU DESKTOP-->
        <ng-container *ngIf="isDesktop">
            <div class="col-12 lg:col-3 min-h-full bg-white">
                <app-menu></app-menu>
            </div>
        </ng-container>
        <!--FIN MENU DESKTOP-->
        <!--INICIO MENU MOBILE-->
        <ng-container *ngIf="!isDesktop">
            <div class="col-12">
                <div class="p-card p-4">
                    <div class="flex flex-row gap-3 justify-content-center align-content-center">
                        <!--INICIO BOTON MENU APP-->
                        <p-button
                            (click)="onToggleVisbleMenu()"
                            pTooltip="menú"
                            icon="pi pi-th-large"
                        ></p-button>
                        <!--FIN BOTON MENU APP-->
                        <!--INICIO LOGO APP-->
                        <a href="/home/index">
                            <img
                                src="assets\img\logos\seguros-equinoccial-logo-full.png"
                                height="50"
                            />
                        </a>
                        <!--FIN LOGO APP-->
                    </div>
                </div>
                <p-sidebar [(visible)]="sidebarVisible" class="w-full">
                    <app-menu></app-menu>
                </p-sidebar>
            </div>
        </ng-container>
        <!--FIN MENU MOBILE-->
        <!--INICIO CONTENIDO DEL SITIO-->
        <div class="col-12 lg:col-9">
            <div class="grid">
                <div class="col-12">
                    <router-outlet></router-outlet>
                </div>
                <div class="col-12">
                    <app-footer-landing-witout-login></app-footer-landing-witout-login>
                </div>
            </div>
        </div>
        <!--FIN CONTENIDO DEL SITIO-->
    </div>
</div>
