<div class="app-menu bg-white relative">
    <div class="grid min-h-full">
        <div class="col-12 p-3">
            <div class="flex flex-row justify-content-center align-content-center">
                <!--INICIO LOGO APP-->
                <a [href]="homeRoute">
                    <img src="assets\img\logos\seguros-equinoccial-logo-full.png" height="50" />
                </a>
                <!--FIN LOGO APP-->
            </div>
        </div>
        <div class="col-12 text-center">
            <!--INICIO MENU USUARIO-->
            <div class="flex flex-column gap-1 justify-content-center align-content-center">
                <div class="avatar">
                    <span class="inicial1">{{ getInitital(1) }}{{ getInitital(2) }}</span>
                </div>
                <p-button type="button" styleClass="p-button-text" (onClick)="menu.toggle($event)">
                    <strong>
                        <span>{{ user?.nombreCompleto }}</span>
                        <i class="pi pi-angle-down ml-2"></i>
                    </strong>
                </p-button>
                <p-menu #menu [model]="menuUser" [popup]="true"></p-menu>
                <p-button
                    type="button"
                    icon="pi pi-sign-out"
                    aria-label="Cerrar Sesión"
                    aria-describedby="Cerrar Sesión"
                    class="p-button-text"
                    pTooltip="Cerrar Sesión"
                    (onClick)="onSignOut()"
                ></p-button>
                <p-menu [model]="menuUser" [popup]="true"></p-menu>
            </div>
            <!--FIN MENU USUARIO-->
        </div>
        <div class="col-12">
            <!--INICIO MENU APP-->
            <div class="p-card">
                <div class="p-card-content p-4">
                    <ng-container *ngIf="esMenuCargado">
                        <app-menu-items
                            *ngFor="let item of obtenerSoloMenuPadres()"
                            [menuItem]="item"
                        ></app-menu-items>
                    </ng-container>
                </div>
            </div>
            <!--FIN MENU APP-->
        </div>
    </div>
</div>
