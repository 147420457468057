import { Component, OnDestroy, OnInit } from '@angular/core';

import { AlertModel } from 'src/app/modules/core/models/alert.model';
import { AlertService } from 'src/app/modules/core/services/alert/alert.service';
import { isTextEmpty } from '../../utils/validators.util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  public visibleToggle:boolean;
  public title:string;
  public message:string;
  public urlRedirect: string;
  public showCloserIcon: boolean;
  public type:string;
  constructor(
    private alertService: AlertService,
    private router: Router
  ) {
    this.visibleToggle = false;
    this.title = '';
    this.message = '';
    this.urlRedirect = '';
    this.showCloserIcon =  true;
    this.type = '';
  }

  ngOnInit(): void {
    this.alertService.alert$.subscribe({
      next: (data: AlertModel) => {
        if (data?.visible) {
          this.title = data?.title || 'Información';
          this.type = data?.type || 'default';
          this.message = data.message || '';
          this.urlRedirect = data.urlRedirect || '';
          this.showCloserIcon = data.showCloserIcon === undefined ? true : data.showCloserIcon;
          this.onShow();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.alertService.alert$.complete();
    this.alertService.alert$.unsubscribe();
  }

  public onShow() {
    this.visibleToggle = true;
  }

  public onClose() {
    if (!isTextEmpty(this.urlRedirect)) {
      window.location.href = this.urlRedirect;
    }
    this.message = '';
    this.visibleToggle = false;
    this.alertService.hiden();
  }

  public isMessageValid(): boolean {
    if (this.message?.length <= 0) return false;
    let messageValidate = this.message;
    messageValidate = messageValidate?.replace(' ', '');
    messageValidate = messageValidate?.replace('\n', '');
    if (messageValidate === '') return false;
    return true;
  }
}
