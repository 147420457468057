<div class="app-topbar-without-login">
    <div class="topbar">
        <div class="logo">
            <a (click)="onHomeLink()">
                <img
                    src="assets\img\logos\seguros-equinoccial-logo-full.png"
                    height="50"
                    class="ml-2 mr-2"
                />
            </a>
        </div>
        <div class="buttons">
            <p-button
                label="Regresar"
                styleClass="p-button-primary p-button-rounded mr-2"
                (onClick)="onReturnLink()"
            ></p-button>
            <p-button
                label="Login"
                styleClass="p-button p-button-rounded"
                (onClick)="onLoginLink()"
                *ngIf="!esSesionActiva"
            ></p-button>
        </div>
    </div>
</div>
