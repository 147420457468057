/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { isTextEmpty } from 'src/app/modules/core/utils/validators.util';
import { SERVICE_PORTAL } from 'src/app/modules/core/data/apis.data';
import { HttpOptionsModel } from 'src/app/modules/core/models/http-options.model';
import { PaginationModel } from 'src/app/modules/core/models/pagination.model';
import { HttpClientService } from 'src/app/modules/core/services/http-client/http-client.service';

import { UserBrokerModel } from '../models/user-broker.model';
import { UserCustomerModel } from '../models/user-customers.model';
import { UserCollaboratorModel } from '../models/user-collaborator.model';
import { UserInsertModel } from '../models/user-insert.model';
import { UserUpdateModel } from '../models/user-update.model';
import { UserModel } from '../models/user.model';
import { InterfaceTypeModel } from '../models/interface-type.model';
import { UserSessionRegisterModel } from '../models/user-session-register.model';
import { UserSessionModel } from '../models/user-session-user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userCreateStep: ReplaySubject<number>;
  constructor(
    private http: HttpClientService
  ) {
    this.userCreateStep = new ReplaySubject<number>();
  }

  public getUserList(
    page: number,
    pageSize: number,
    onlyActive: boolean,
    textFilter: string
  ): Observable<PaginationModel<UserModel>> {
    let filterParams = '';
    filterParams += `paginado?paginaActual=${page}&registrosPorPagina=${pageSize}&soloActivos=${onlyActive}`;
    if (!isTextEmpty(textFilter)) {
      filterParams += `&filtrarPorTexto=${textFilter}`;
    }
    return this.http.get<PaginationModel<UserModel>>(
      `/v1/usuario/${filterParams}`,
      SERVICE_PORTAL
    );
  }

  public getUserById(userId: string): Observable<UserModel> {
    return this.http.get<UserModel>(
      `/v1/usuario/${userId}`,
      SERVICE_PORTAL
    );
  }

  public validateIdentityByCodeId(userCodeId: string, code: string): Observable<UserModel> {
    return this.http.get<UserModel>(
      `/v1/usuario/${userCodeId}/validarIdentidad/${code}`,
      SERVICE_PORTAL
    );
  }

  public GetUserByEmail(email: string): Observable<UserModel> {
    return this.http.get<UserModel>(
      `/v1/usuario/getByEmail?email=${email}`,
      SERVICE_PORTAL
    );
  }

  public editStatus(id: string, status: boolean) {
    return this.http.put<string>(
      `/v1/usuario/${id}/estadoActivo/${status}`,
      SERVICE_PORTAL
    );
  }

  public createUser(userInsert: UserInsertModel): Observable<string> {
    const options: HttpOptionsModel = {
      responseType: 'text'
    };
    return this.http.post<string>(
      '/v1/usuario/crear',
      SERVICE_PORTAL,
      userInsert,
      options
    );
  }

  public editUser(userId: string ,userInsert: UserUpdateModel): Observable<void> {
    return this.http.put(
      `/v1/usuario/${userId}/editar`,
      SERVICE_PORTAL,
      userInsert
    );
  }

  public registerBroker(userInsert: UserBrokerModel): Observable<string> {
    const options: HttpOptionsModel = {
      responseType: 'text'
    };
    return this.http.post<string>(
      '/v1/usuario/registarTipoBroker',
      SERVICE_PORTAL,
      userInsert,
      options
    );
  }

  public registerCustomer(userInsert: UserCustomerModel) : Observable<string> {
    const options: HttpOptionsModel = {
      responseType: 'text'
    };
    return this.http.post<string>(
      '/v1/usuario/registerCustomer',
      SERVICE_PORTAL,
      userInsert,
      options
    );
  }

  public registerCollaborator(userInsert: UserCollaboratorModel) {
    const options: HttpOptionsModel = {
      responseType: 'text'
    };
    return this.http.post<string>(
      '/v1/usuario/registerCollaborator',
      SERVICE_PORTAL,
      userInsert,
      options
    );
  }

  public getListFilter(name: string, onlyActive: boolean, onlyParent: boolean): Observable<UserModel[]> {
    let nameUser = '';
    if (isTextEmpty(name)) nameUser = '';
    else nameUser =  `&name=${name}`;
    return this.http.get<UserModel[]>(
      `/v1/usuario/list/filter?onlyActive=${onlyActive}&onlyParent=${onlyParent}${nameUser}`,
      SERVICE_PORTAL
    );
  }

  public restPassword(userId: string, password: string, sessionUserRegisterModel: UserSessionRegisterModel) {
    const body = {
      clave: password,
      registroSesionUsuario: sessionUserRegisterModel
    };
    return this.http.put(
      `/v1/usuario/${userId}/cambiarClave`,
      SERVICE_PORTAL,
      body
    );
  }

  public setCreateStep(userCreateStep: number): void {
    this.userCreateStep.next(userCreateStep);
  }

  public  getCreateStepSubcription(): Observable<number> {
    return this.userCreateStep.asObservable();
  }

  public asignMenuList(userId: string, body: string[]): Observable<void> {
    return this.http.put(
      `/v1/usuario/${userId}/asignarMenus`,
      SERVICE_PORTAL,
      body
    );
  }

  public getInterfaceTypeList(): Observable<InterfaceTypeModel[]> {
    return this.http.get<InterfaceTypeModel[]>(
      '/v1/usuario/tipoInterfaces/lista',
      SERVICE_PORTAL
    );
  }

  public registerSession(sessionUserModel: UserSessionRegisterModel): Observable<UserSessionModel> {
    return this.http.post<UserSessionModel>('/v1/usuario/sessionUser', SERVICE_PORTAL, sessionUserModel);
  }

  public isSessionActive(): Observable<UserSessionModel> {
    return this.http.get<UserSessionModel>(
      '/v1/usuario/validarSesesion',
      SERVICE_PORTAL
    );
  }

  public getAllSessionByUserId(userId: string, onlyActive: boolean): Observable<UserSessionModel[]> {
    return this.http.get<UserSessionModel[]>(
      `/v1/sessionUser/list?userId=${userId}?onlyActive=${onlyActive}`,
      SERVICE_PORTAL
    );
  }

  public deleteSession(userId: string): Observable<UserSessionModel> {
    return this.http.delete<UserSessionModel>(`/v1/sessionUser/${userId}`, SERVICE_PORTAL);
  }
}
