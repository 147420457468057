import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { MenuModel } from 'src/app/modules/configurations/menus/model/menu.model';

import { isTextEmpty } from '../../utils/validators.util';
import { StoreService } from '../../services/store/store.service';
import { SidebarService } from '../../services/sidebar/sidebar.service';

@Component({
    selector: 'app-menu-items',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent implements OnInit, OnDestroy {
    @Input() menuItem!: MenuModel;

    public listaMenuSubscription: Subscription = new Subscription();
    public listaMenu: MenuModel[] = [];

    constructor(
        private router: Router,
        private storeService: StoreService,
        private sidebarService: SidebarService
    ) {}

    public ngOnDestroy(): void {
        this.listaMenuSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.obtenerListaMenus();
    }

    public obtenerListaMenus(): void {
        this.listaMenuSubscription = this.storeService
            .getMenuListSubscription()
            .subscribe(listaMenus => {
                this.listaMenu = listaMenus;
            });
    }

    public tieneMenuHijos(menuItem: MenuModel): boolean {
        return this.obtenerMenuHijos(menuItem).length > 0;
    }

    public obtenerMenuHijos(menuItem: MenuModel): MenuModel[] {
        const menuHijos = this.listaMenu.filter(
            m => m.parentMenuId?.toLowerCase() === menuItem.id.toLowerCase()
        );
        return menuHijos;
    }

    public enClickMenu(): void {
        this.menuItem.expandido = !this.menuItem.expandido;
        this.listaMenu.forEach(m => {
            if (m.id.toLowerCase() === this.menuItem.id.toLowerCase()) {
                m.expandido = this.menuItem.expandido;
                if (!this.tieneMenuHijos(m)) {
                    m.seleccionado = true;
                }
            } else {
                m.seleccionado = false;
            }
        });
        this.storeService.setMenuList(this.listaMenu);
        if (!isTextEmpty(this.menuItem.route)) {
            try {
                this.sidebarService.show(false);
                this.router.navigate([this.menuItem.route]);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error al abrir ruta del menu:', error);
            }
        }
    }
}
