import { NgModule } from '@angular/core';

import { HighchartsChartModule } from 'highcharts-angular';

const libraries = [HighchartsChartModule];

@NgModule({
    imports: [libraries],
    exports: [libraries],
})
export class UtilLibrary {}
