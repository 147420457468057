<div #menu class="app-menu-items" *ngIf="menuItem !== null">
    <div
        class="flex flex-row gap-2 justify-content-between cursor-pointer p-3"
        [ngClass]="{
            'font-bold':
                tieneMenuHijos(menuItem) ||
                menuItem.parentMenuId === null ||
                menuItem.parentMenuId === '',
            'border-menu-seleccionado': menuItem.seleccionado
        }"
        (click)="enClickMenu()"
    >
        <div>
            <i class="{{ menuItem.icono }} mr-2" *ngIf="menuItem.icono !== ''"></i>
            <span>{{ menuItem.name }}</span>
        </div>
        <i class="pi pi-chevron-right" *ngIf="tieneMenuHijos(menuItem) && !menuItem.expandido"></i>
        <i class="pi pi-chevron-down" *ngIf="tieneMenuHijos(menuItem) && menuItem.expandido"></i>
    </div>
    <div
        *ngIf="tieneMenuHijos(menuItem) && menuItem.expandido"
        class="ml-4 border-left-1"
        style="background-color: #eff3f8"
        #menuPrincipal
    >
        <app-menu-items
            *ngFor="let hijo of obtenerMenuHijos(menuItem)"
            [menuItem]="hijo"
        ></app-menu-items>
    </div>
</div>
