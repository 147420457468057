import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { RecoveryGuard } from './modules/auth/guards/recovery.guard';
import { BreadCrumbGuard } from './modules/core/guards/breadcrumb.guard';
import { AppLayoutComponent } from './modules/core/components/app-layout/app-layout.component';
import {
    APP_ROUTE_AUTH,
    APP_ROUTE_CLAIMS,
    APP_ROUTE_CONFIGURATIONS,
    APP_ROUTE_ETHICS_COMPLAINTS,
    APP_ROUTE_HOME,
    APP_ROUTE_LOANS_AND_WITH_DRAWALS,
    APP_ROUTE_USER_MANAGER_ACCOUNT,
    APP_ROUTE_POLICES,
    APP_ROUTE_INCLUSIONS,
    APP_ROUTE_PAYMENT_CONDUITS,
    APP_ROUTE_CLAIMS_WITH_LOGIN,
    APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST,
    APP_ROUTE_ESTADO_CUENTA,
    APP_ROUTE_EXCLUSIONS,
    APP_ROUTE_COTIZADOR,
    APP_ROUTE_TRAZABILIDAD_APLICACIONES,
} from './modules/core/data/app-routes.data';

const routes: Routes = [
    {
        path: '',
        component: AppLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: APP_ROUTE_HOME,
                redirectTo: APP_ROUTE_POLICES,
            },
            {
                path: APP_ROUTE_CONFIGURATIONS,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/configurations/configurations.module').then(
                        m => m.ConfigurationsModule
                    ),
            },
            {
                path: APP_ROUTE_USER_MANAGER_ACCOUNT,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/manage-account/manage-account.module').then(
                        m => m.ManageAccountModule
                    ),
            },
            {
                path: APP_ROUTE_POLICES,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/policies/policies.module').then(m => m.PoliciesModule),
            },
            {
                path: APP_ROUTE_LOANS_AND_WITH_DRAWALS,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/loan-withdrawal/loan-withdrawal.module').then(
                        m => m.LoanWithdrawalModule
                    ),
            },
            {
                path: APP_ROUTE_PAYMENT_CONDUITS,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/payment-conduits/payment-conduits.module').then(
                        m => m.PaymentConduitsModule
                    ),
            },
            {
                path: APP_ROUTE_INCLUSIONS,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/inclusions/inclusions.module').then(m => m.InclusionsModule),
            },
            {
                path: APP_ROUTE_EXCLUSIONS,
                loadChildren: () =>
                    import('./modules/inclusions/vehicle-exclusion/vehicle-exclusion.module').then(
                        m => m.VehicleExclusionModule
                    ),
            },
            {
                path: APP_ROUTE_ESTADO_CUENTA,
                loadChildren: () =>
                    import('./modules/estado-cuenta/estado-cuenta.module').then(
                        m => m.EstadoCuentaModule
                    ),
            },
            {
                path: APP_ROUTE_CLAIMS_WITH_LOGIN,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/claims/claims.module').then(m => m.ClaimsModule),
            },
            {
                path: APP_ROUTE_COTIZADOR,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/cotizadores/cotizadores.module').then(
                        m => m.CotizadoresModule
                    ),
            },
            {
                path: APP_ROUTE_TRAZABILIDAD_APLICACIONES,
                canActivate: [BreadCrumbGuard, AuthGuard, RecoveryGuard],
                loadChildren: () =>
                    import('./modules/trazabilidad-aplicaciones/trazabilidad-aplicaciones.module').then(m => m.TrazabilidadAplicacionesModule),
            },
        ],
    },
    {
        path: APP_ROUTE_AUTH,
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: APP_ROUTE_CLAIMS,
        loadChildren: () => import('./modules/claims/claims.module').then(m => m.ClaimsModule),
    },
    {
        path: APP_ROUTE_ETHICS_COMPLAINTS,
        loadChildren: () =>
            import('./modules/ethics-complaint/ethics-complaint.module').then(
                m => m.EthicsComplaintModule
            ),
    },
    {
        path: APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST,
        loadChildren: () =>
            import(
                './modules/account-registration-request/account-registration-request.module'
            ).then(m => m.AccountRegistrationRequestModule),
    },
    {
        path: 'claims',
        redirectTo: 'siniestros',
    },
    {
        path: 'configuration',
        redirectTo: 'configuraciones',
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: APP_ROUTE_HOME,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
