import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SERVICE_PORTAL } from 'src/app/modules/core/data/apis.data';
import { HttpOptionsModel } from 'src/app/modules/core/models/http-options.model';
import { getAuthorizationHttpHeader } from 'src/app/modules/core/utils/http.ulti';
import { environment } from 'src/environments/environment';

import { StoreService } from '../store/store.service';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  private readonly apis = new Map();
  constructor(
    private http: HttpClient,
    private storeService: StoreService
  ) {
    this.apis.set(SERVICE_PORTAL, environment.apis.servicePortal);
  }

  public get<T>(endpoint: string, apiName: string, httpOptionsModel?: HttpOptionsModel): Observable<T> {
    const validOptions = this.getValidOptions(apiName, httpOptionsModel);
    const url =  this.apis.get(apiName) || '';
    return this.http.get<T>(`${url}${endpoint}`, validOptions);
  }

  public post<T>(endpoint: string, apiName: string, body: object, httpOptionsModel?: HttpOptionsModel): Observable<T> {
    const validOptions = this.getValidOptions(apiName, httpOptionsModel);
    const url =  this.apis.get(apiName) || '';
    return this.http.post<T>(`${url}${endpoint}`, body, validOptions);
  }

  public put<T>(endpoint: string, apiName: string, body?: object, httpOptionsModel?: HttpOptionsModel): Observable<T> {
    const validOptions = this.getValidOptions(apiName, httpOptionsModel);
    const url =  this.apis.get(apiName) || '';
    return this.http.put<T>(`${url}${endpoint}`, body, validOptions);
  }

  public patch<T>(endpoint: string, apiName: string, body: object, httpOptionsModel?: HttpOptionsModel): Observable<T> {
    const validOptions = this.getValidOptions(apiName, httpOptionsModel);
    const url =  this.apis.get(apiName) || '';
    return this.http.patch<T>(`${url}${endpoint}`, body, validOptions);
  }

  public delete<T>(endpoint: string, apiName: string, httpOptionsModel?: HttpOptionsModel): Observable<T> {
    const httpOptionsModelVerificadas = this.getValidOptions(apiName, httpOptionsModel);
    const url =  this.apis.get(apiName) || '';
    return this.http.delete<T>(`${url}${endpoint}`, httpOptionsModelVerificadas);
  }

  private getValidOptions(apiName: string, httpOptionsModel?: HttpOptionsModel): HttpOptionsModel {
    const validOptions = httpOptionsModel || {};
    validOptions.responseType = 'json';
    if (httpOptionsModel?.responseType) {
      validOptions.responseType = httpOptionsModel.responseType;
    }
    if (httpOptionsModel?.headers) {
      validOptions.headers = httpOptionsModel.headers;
    } else {
      validOptions.headers = new HttpHeaders();
    }
    if (apiName === SERVICE_PORTAL) {
      const token = this.storeService.getAuthToken();
      const authorizationHttpHeader = getAuthorizationHttpHeader(token);
      if (authorizationHttpHeader) {
        validOptions.headers = authorizationHttpHeader;
      }
    }
    return validOptions;
  }
}
