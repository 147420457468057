import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { MenuModel } from 'src/app/modules/configurations/menus/model/menu.model';
import { UserModel } from 'src/app/modules/configurations/users/models/user.model';
import { isTextEmpty } from 'src/app/modules/core/utils/validators.util';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    private urlRecoveryKey: string;
    private authTokenKey: string;
    private authUserKey: string;
    private menuListKey: string;

    private listaMenus: ReplaySubject<MenuModel[]> = new ReplaySubject<MenuModel[]>();

    constructor() {
        this.urlRecoveryKey = 'sesa_ps_url_recovery';
        this.authTokenKey = 'sesa_ps_auth_token';
        this.authUserKey = 'sesa_ps_auth_user';
        this.menuListKey = 'sesa_ps_menu_list';
    }

    public setUrlRecovery(url: string): void {
        if (!isTextEmpty(url)) {
            localStorage.setItem(this.urlRecoveryKey, url);
        }
    }

    public getUrlRecovery(): string {
        const urlRecovery = localStorage.getItem(this.urlRecoveryKey) || '';
        return urlRecovery;
    }

    public setAuthToken(token: string): void {
        if (!isTextEmpty(token)) {
            localStorage.setItem(this.authTokenKey, token);
        }
    }

    public getAuthToken(): string {
        const token = localStorage.getItem(this.authTokenKey) || '';
        return token;
    }

    public setAuthUser(user: UserModel | null): void {
        if (user) {
            const json = JSON.stringify(user);
            localStorage.setItem(this.authUserKey, json);
        }
    }

    public getAuthUser(): UserModel | null {
        const textJson = localStorage.getItem(this.authUserKey) || '';
        if (!isTextEmpty(textJson)) {
            const user: UserModel = JSON.parse(textJson) || null;
            return user;
        }
        return null;
    }

    public setMenuList(menuList: MenuModel[]): void {
        const menuListJson = JSON.stringify(menuList);
        localStorage.setItem(this.menuListKey, menuListJson);
        this.listaMenus.next(menuList);
    }

    public getMenuList(): MenuModel[] {
        const textJson = localStorage.getItem(this.menuListKey) || '';
        if (!isTextEmpty(textJson)) {
            const menuList: MenuModel[] = JSON.parse(textJson) || [];
            return menuList;
        }
        return [];
    }

    public getMenuListSubscription(): Observable<MenuModel[]> {
        const textJson = localStorage.getItem(this.menuListKey) || '';
        if (!isTextEmpty(textJson)) {
            const menuList: MenuModel[] = JSON.parse(textJson) || [];
            this.listaMenus.next(menuList);
        } else {
            this.listaMenus.next([]);
        }
        return this.listaMenus.asObservable();
    }

    public removeAuthSession(): void {
        localStorage.removeItem(this.authTokenKey);
        localStorage.removeItem(this.authUserKey);
        localStorage.removeItem(this.menuListKey);
    }

    public isBroker(): boolean {
        const user = this.getAuthUser();
        if (user?.tipoInterfaz?.esCorredorRequerido) {
            return true;
        }
        return false;
    }
}
