import { Component } from '@angular/core';

@Component({
  selector: 'app-message-policy-no-active',
  templateUrl: './message-policy-no-active.component.html',
  styleUrls: ['./message-policy-no-active.component.scss']
})
export class MessagePolicyNoActiveComponent {

}
