
<footer class="app-footer-landing-witout-login">
    <div class="foot-container">
        <div class="title-container">
            <h2 class="pre-title">¿Necesitas ayuda?</h2>
            <h2 class="foot-title">Comunícate con nosotros a través de nuestros canales y recibe la mejor asesoría</h2>
        </div>
        <div class="contacts-container">
            <a href="tel:1800378466" target="_blank" class="info-card">
                <i class="pi pi-phone" style="font-size: 2rem"></i>
                <h2 class="card-title">1800-Equinoccial</h2>
                <div class="card-value">(1800-378466)</div>
            </a>
            
            <a href="https://api.whatsapp.com/send/?phone=5930962996666&text&type=phone_number&app_absent=0" target="_blank" class="info-card">
                <i class="pi pi-whatsapp" style="font-size: 2rem"></i>
                <h2 class="card-title">Whatsapp</h2>
                <div class="card-value">096 299 6666</div>
            </a>
            <!--
            <a href="https://t.me/sesar_thebot" target="_blank" class="info-card">
                <i class="pi pi-telegram" style="font-size: 2rem"></i>
                <h2 class="card-title">Telegram</h2>
                <div class="card-value">@segurosequinoccial</div>
            </a>
   
            <a href="" target="_self" class="info-card">
                <i class="pi pi-comments" style="font-size: 2rem"></i>
                <h2 class="card-title">Chat</h2>
                <div class="card-value">segurosequinoccial.com</div>
            </a>
            -->
            <a href="mailto:info@segurosequinoccial.com" target="_blank" class="info-card">
                <i class="pi pi-envelope" style="font-size: 2rem"></i>
                <h2 class="card-title">Escríbenos</h2>
                <div class="card-value">info@segurosequinoccial.com</div>
            </a>
        </div>
    </div>
</footer>
  